import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const FW21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "eclipse-100-velvet-boots",
      "eclipse-75-velvet-boots",
      "galaxy-velvet-boots",
      "eris-velvet-boots",
      "setter-velvet-flats",
    ].map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Jewel Tone Velvet"
        description="Fall Winter '21"
        url={location.pathname}
      />

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`story-jewel-tone-velvet`}
          />
        ))}
        <li className={styles.storyItem31a}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_JewelToneVelvet_2_1320x.jpg?v=1629408020",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_JewelToneVelvet_2_850x.jpg?v=1629408020",
            }}
            altText="Jewel Tone Story"
          />
        </li>
      </ul>
    </>
  )
}

export default FW21

export const query = graphql`
    query JewelToneVelvetQuery {
        allProductStyle(filter: {handle: {in: [
            "eclipse-100-velvet-boots",
            "eclipse-75-velvet-boots",
            "galaxy-velvet-boots",
            "eris-velvet-boots",
            "setter-velvet-flats",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
